import { StaticImage } from 'gatsby-plugin-image';

export const speakers = [
  {
    name: 'Dr. Elizabeth Klein',
    title: 'Assistant Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/elizabeth-klein.jpg"
        alt="Dr. Elizabeth Klein"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Klein specializes in patristic theology, particularly the writings
        of St. Augustine. She completed her PhD from the University of Notre
        Dame in 2016, and after holding a postdoctoral position at Notre Dame,
        she came to teach at the Augustine Institute in 2017. She is the author
        of <em>Augustine’s Theology of Angels</em> (Cambridge University Press,
        2018) and <em>God: What Every Catholic Should Know</em> (Ignatius Press;
        Augustine Institute, 2019).
      </p>
    ),
  },
  {
    name: 'Dr. James Prothro',
    title: 'Assistant Professor of Scripture and Theology',
    image: (
      <StaticImage
        src="../../images/speakers/james-prothro.jpg"
        alt="Dr. James Prothro"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Prothro has taught a wide range of topics, especially in the New
        Testament, and holds degrees in biblical languages, in classical Greek
        and Latin, and in theology. Dr. Prothro is also engaged in thinking and
        writing about the doctrine of biblical inspiration and how it helps us
        understand what the Bible is and how to read it for all its worth. He
        has recently completed his third book on the Apostle Paul and is
        currently writing a biblical-theological study of penance and
        reconciliation.
      </p>
    ),
  },
  {
    name: 'Dr. John Sehorn',
    title: 'Academic Dean, Associate Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/john-sehorn.jpg"
        alt="Dr. John Sehorn"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Sehorn specializes in the theology of the Fathers of the Church,
        with a particular interest in their scriptural exegesis. He received his
        doctorate from the University of Notre Dame and has been a member of the
        faculty at the Augustine Institute since 2015. He is currently
        co-editing the series{' '}
        <em>
          From the Side of Christ: A Biblical Theology of the Sacraments for
          Baker Academic
        </em>
        .
      </p>
    ),
  },
  {
    name: 'Dr. Israel McGrew',
    title: 'Assistant Professor of Sacred Scripture',
    image: (
      <StaticImage
        src="../../images/speakers/israel-mcgrew.jpg"
        alt="Dr. Israel McGrew"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. McGrew is an Old Testament scholar and specializes in the Book of
        Job. He is also especially interested in bringing Biblical Studies into
        a deeper dialogue with the Church's traditional, Christological readings
        of the Old Testament. He is currently working with Cambridge University
        Press on a book on Job's parabolic dramatization of Israel's scriptural
        and mythological traditions titled,{' '}
        <em>
          The Riddle of Job: Allegorical Representation and Argumentation in the
          Book of Job
        </em>
        .
      </p>
    ),
  },
  {
    name: 'Dr. Christopher Mooney',
    title: 'Assistant Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/christopher-mooney.jpg"
        alt="Dr. Christopher Mooney"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Mooney is a historical theologian specializing in Augustine and the
        Augustinian theological legacy, particularly regarding the nature of
        faith and grace. He has published in academic journals on the history of
        exegesis of James 2:24, Augustine's preaching on forgiveness, and more.
        Dr. Mooney is currently working on publishing his revised dissertation,
        "<em>Iustitia fidei</em>: The Development of Augustine's Account of
        Justification by Faith." He also serves as a theological representative
        in the US Catholic-Reformed ecumenical dialogue on justification and
        justice.
      </p>
    ),
  },
  {
    name: 'Dr. Jessica Murdoch ',
    title: 'Visiting Associate Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/jessica-murdoch.jpg"
        alt="Dr. Jessica Murdoch "
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Murdoch specializes in the philosophy and theology of St. Thomas
        Aquinas, metaphysics, epistemology, and papal teaching. She earned her
        Ph.D. in Systematic Theology from Fordham University. Her articles have
        appeared in academic journals such as <em>Philosophy and Theology</em>,{' '}
        <em>Pro Ecclesia: Journal of Catholic and Evangelical Theology</em>, and
        the <em>New Blackfriars Review</em>. She has written two articles
        currently under review, titled “Entropy, Sin and Thomistic Order” and
        “On the Disposition of Metaphysics: Violence, Truth, Love.”
      </p>
    ),
  },
];
