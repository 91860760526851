export const schedule = [
  {
    time: '11:00 am MST',
    title: 'Welcome and Mission of the Augustine Institute',
  },
  {
    time: '11:15 am MST',
    title:
      'The Principles of Theological Study — Dr. James Prothro, Dr. Elizabeth Klein, Dr. John Sehorn',
    excerpt:
      'What exactly is included in the study of theology? Which topics are essential to a comprehensive understanding of Divine Revelation?',
    desc: 'What exactly is included in the study of theology? Which topics are essential to a comprehensive understanding of Divine Revelation? How should one understand the study of God as a discipline? Our professors will dive into Sacred Scripture, sacred Doctrine, and the history and mission of the Church to answer these important questions.',
  },
  {
    time: '12:00 pm MST',
    title:
      'Studying Theology at the Augustine Institute: Scholarship Opportunities and Next Steps',
    excerpt:
      'Whether you are considering studying on-campus in Denver, Colorado, or via our renowned distance education program, we have select scholarship opportunities available.',
    desc: 'Whether you are considering studying on-campus in Denver, Colorado, or via our renowned distance education program, we have select scholarship opportunities available. You will hear briefly from the Director of Admissions, who will provide details on how to fund your studies as well as what you can expect from the application process and how to continue discerning an advanced degree in Theology.',
  },
  {
    time: '12:05 - 1:00 pm MST',
    title: 'Breakout sessions',
    subtitle: '(see below)',
  },
];
