import { StaticImage } from 'gatsby-plugin-image';
import { breakoutSessionsRaw } from './breakoutSessionsRaw';

const images = {
  'israel-mcgrew': (
    <StaticImage
      src="../../images/speakers/israel-mcgrew.jpg"
      alt="Dr. Israel McGrew"
      layout="fullWidth"
    />
  ),
  'jessica-murdoch': (
    <StaticImage
      src="../../images/speakers/jessica-murdoch.jpg"
      alt="Dr. Jessica Murdoch"
      layout="fullWidth"
    />
  ),
  'christopher-mooney': (
    <StaticImage
      src="../../images/speakers/christopher-mooney.jpg"
      alt="Dr. Christopher Mooney"
      layout="fullWidth"
    />
  ),
};

export const breakoutSessions = breakoutSessionsRaw.map((session) => ({
  ...session,
  image: images[session.image as keyof typeof images],
}));
