import { StaticImage } from 'gatsby-plugin-image';
import { RiCalendar2Fill, RiTimeLine } from 'react-icons/ri';
import { HeadProps } from 'gatsby';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
  Speakers,
  Schedule,
} from '@augustine-institute/lib';
import SEO from '../components/Seo';
import Bonus from '../components/2022/Bonus';
import Form from '../components/2022/Form';
import Sessions from '../components/Sessions';
import EventImage from '../components/2022/EventImage';
import {
  EVENT_START,
  EVENT_END,
  EVENT_TITLE,
  EVENT_SUBTITLE,
} from '../utils/2022/config';
import { speakers } from '../data/2022/speakers';
import { schedule } from '../data/2022/schedule';
import { breakoutSessions } from '../data/2022/breakoutSessions';
import PageHeader from '../components/PageHeader';

// * This assumes that event start & end dates are the same day.

const dateString = EVENT_START.toLocaleDateString('en-US', {
  weekday: 'long',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});

// Get the time string - range between EVENT_START and EVENT_END
const timeString = `${EVENT_START.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
})} - ${EVENT_END.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
  timeZoneName: 'short',
})}`;

const startTimeString = EVENT_START.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
  timeZoneName: 'short',
});

export default function HomePage() {
  const eventStart = EVENT_START;
  const today = new Date();
  const msToEvent = eventStart.valueOf() - today.valueOf();
  const minutesToEvent = Math.floor(msToEvent / 1000 / 60);
  const hoursToEvent = Math.floor(msToEvent / 1000 / 60 / 60);
  const daysToEvent = Math.floor(msToEvent / 1000 / 60 / 60 / 24);

  const string =
    // eslint-disable-next-line no-nested-ternary
    daysToEvent > 0
      ? `${daysToEvent} days`
      : hoursToEvent > 0
      ? `${hoursToEvent} hours`
      : `${minutesToEvent} minutes`;

  return (
    <main className="page-2022 page-m-t">
      <section className="container content-m-b">
        <div className="row">
          <div className="col-xs-12">
            <PageHeader
              className="hide-md"
              title={EVENT_TITLE}
              subtitle={EVENT_SUBTITLE}
              date={dateString}
              time={timeString}
            />
          </div>
        </div>
      </section>
      <section className="container section-m-b">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-7">
            <PageHeader
              className="hide-xs show-md"
              title={EVENT_TITLE}
              subtitle={EVENT_SUBTITLE}
              date={dateString}
              time={timeString}
            />

            <div className="event-desc text-columns">
              <EventImage className="hide-sm" />
              <p>
                If you are discerning theological studies at the graduate level,
                join us in heeding the exhortation to the Hebrews:
              </p>
              <p>
                <strong>
                  “We earnestly desire each of you to demonstrate the same
                  eagerness for the fulfillment of hope until the end”
                </strong>{' '}
                (Hebrews 6:11).
              </p>
              <p>
                The professors at the Graduate School will discuss the
                principles of theological study, and then you will have the
                opportunity to interact with a faculty member in a breakout
                session on a topic typical to one of our classes.
              </p>
              <p>
                Today, hope is needed more than ever. Amid problems in the world
                and in the Church, it can be easy to lose hope.
              </p>
              <EventImage className="hide-xs show-sm hide-md" />
              <p>
                The study of theology in Catholic doctrine, Church history, and
                Sacred Scripture reminds us of where our hope lies: in the Lord
                and his providence.
              </p>
              <p>
                Join us for this Virtual Theology Summit to hear about St.
                Thomas Aquinas on the theological virtue of hope, the
                transformation of hope in the book of Job, and the hope of the
                martyrs.
              </p>
              <EventImage className="hide-xs show-md" />
            </div>
            <div className="hide-xs show-md">
              <Bonus />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-5">
            <div className="registration">
              <div className="registration__inner">
                <div className="registration__dots hide-xs show-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 90 230"
                  >
                    <circle cx="25" cy="25" r="5" fill="#fff" />
                    <circle cx="25" cy="145" r="5" fill="#fff" />
                    <circle cx="65" cy="145" r="5" fill="#fff" />
                    <circle cx="45" cy="25" r="5" fill="#fff" />
                    <circle cx="45" cy="145" r="5" fill="#fff" />
                    <circle cx="85" cy="145" r="5" fill="#fff" />
                    <circle cx="25" cy="45" r="5" fill="#fff" />
                    <circle cx="25" cy="165" r="5" fill="#fff" />
                    <circle cx="65" cy="165" r="5" fill="#fff" />
                    <circle cx="45" cy="45" r="5" fill="#fff" />
                    <circle cx="45" cy="165" r="5" fill="#fff" />
                    <circle cx="85" cy="165" r="5" fill="#fff" />
                    <circle cx="25" cy="65" r="5" fill="#fff" />
                    <circle cx="25" cy="185" r="5" fill="#fff" />
                    <circle cx="65" cy="185" r="5" fill="#fff" />
                    <circle cx="45" cy="65" r="5" fill="#fff" />
                    <circle cx="45" cy="185" r="5" fill="#fff" />
                    <circle cx="85" cy="185" r="5" fill="#fff" />
                    <circle cx="25" cy="85" r="5" fill="#fff" />
                    <circle cx="25" cy="205" r="5" fill="#fff" />
                    <circle cx="65" cy="85" r="5" fill="#fff" />
                    <circle cx="65" cy="205" r="5" fill="#fff" />
                    <circle cx="45" cy="85" r="5" fill="#fff" />
                    <circle cx="45" cy="205" r="5" fill="#fff" />
                    <circle cx="85" cy="85" r="5" fill="#fff" />
                    <circle cx="85" cy="205" r="5" fill="#fff" />
                    <circle cx="25" cy="105" r="5" fill="#fff" />
                    <circle cx="25" cy="225" r="5" fill="#fff" />
                    <circle cx="65" cy="105" r="5" fill="#fff" />
                    <circle cx="65" cy="225" r="5" fill="#fff" />
                    <circle cx="45" cy="105" r="5" fill="#fff" />
                    <circle cx="45" cy="225" r="5" fill="#fff" />
                    <circle cx="85" cy="105" r="5" fill="#fff" />
                    <circle cx="85" cy="225" r="5" fill="#fff" />
                    <circle cx="25" cy="125" r="5" fill="#fff" />
                    <circle cx="65" cy="125" r="5" fill="#fff" />
                    <circle cx="45" cy="125" r="5" fill="#fff" />
                    <circle cx="85" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="5" r="5" fill="#fff" />
                    <circle cx="5" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="25" r="5" fill="#fff" />
                    <circle cx="5" cy="145" r="5" fill="#fff" />
                    <circle cx="5" cy="45" r="5" fill="#fff" />
                    <circle cx="5" cy="165" r="5" fill="#fff" />
                    <circle cx="5" cy="65" r="5" fill="#fff" />
                    <circle cx="5" cy="185" r="5" fill="#fff" />
                    <circle cx="5" cy="85" r="5" fill="#fff" />
                    <circle cx="5" cy="205" r="5" fill="#fff" />
                    <circle cx="5" cy="105" r="5" fill="#fff" />
                  </svg>
                </div>
                {(daysToEvent >= -1 && (
                  <>
                    <div className="registration__title">
                      {minutesToEvent >= 0 ? (
                        <>
                          <h2 className="h5" style={{ marginBottom: '0.25em' }}>
                            Event begins in
                          </h2>
                          <h3 className="h2">{string}</h3>
                        </>
                      ) : (
                        <h2 className="h5" style={{ marginBottom: '0.25em' }}>
                          Event has begun
                        </h2>
                      )}
                    </div>
                    <div className="registration__form">
                      <Form />
                    </div>
                  </>
                )) || (
                  <div className="registration__title">
                    <h3 className="h2">Event has ended</h3>
                    <h2 className="h5">Thank you for your participation!</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 hide-md">
            <Bonus />
          </div>
        </div>
      </section>
      <section className="container section-m-b">
        <Tabs id="courses">
          <TabList>
            <Tab id="event-schedule">
              <span className="h3">Event Schedule</span>
            </Tab>
            <Tab id="course-of-study2">
              <span className="h3">Speakers</span>
            </Tab>
          </TabList>
          <TabPanelWrapper>
            <TabPanel>
              <p className="narrow">
                This livestream event will start at{' '}
                <strong>
                  {startTimeString} on {dateString}
                </strong>
                . A weblink will be provided after registration to view the
                following program online:
              </p>
              <Schedule schedule={schedule} />
              <div className="breakout-sessions">
                <Sessions sessions={breakoutSessions} />
              </div>
            </TabPanel>
            <TabPanel>
              <Speakers speakers={speakers} />
            </TabPanel>
          </TabPanelWrapper>
        </Tabs>
      </section>
    </main>
  );
}

export function Head({ location: { pathname } }: HeadProps) {
  return (
    <SEO
      title="Virtual Theology Summit at Augustine Institute"
      description="Professors will discuss the principles of theological study, giving the opportunity to interact with a faculty member afterwards."
      pathname={pathname}
    />
  );
}
