import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  FormSubmitButton,
  SnackbarContext,
  AppleCalendar,
  GoogleCalendar,
  Office365,
  Outlook,
  Yahoo,
  FormMessageTooltip,
  Checkbox,
} from '@augustine-institute/lib';
import { breakoutSessions } from '../../data/2022/breakoutSessions';
// import Script from '../Script';

declare global {
  interface Window {
    lintrk: Function;
  }
}
interface FormMessageTypes {
  status: string;
  message: string;
  open: boolean;
}

interface Inputs {
  [key: string]: string | number | boolean | undefined;
  'form-name': string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  session: string;
  interest: string;
  questions?: string;
  country?: string;
}

interface CalendarProps {
  google: string;
  yahoo: string;
  outlook: string;
  office: string;
  ics: string;
}

export default function Form() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [calendars, setCalendars] = React.useState<CalendarProps | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const [formMessage, setFormMessage] = React.useState<FormMessageTypes>({
    status: '',
    message: '',
    open: false,
  });

  // Set or Unset form message
  function handleResponse(response: Response) {
    // console.log(response);
    if (response.ok) {
      setFormMessage({
        status: 'success',
        message: `Thank you. Your message has been sent. We will respond to your message as soon as possible.`,
        open: true,
      });
      reset(); // Clear form on success
    } else {
      setFormMessage({
        status: 'error',
        message: `Sorry, there's an error in sending your message. Please try again later.`,
        open: true,
      });
    }
  }

  function encode(data: Inputs) {
    return Object.keys(data)
      .map((key) => {
        const value = data[key];

        if (typeof value === 'object') {
          return Object.keys(value)
            .filter((k) => typeof value[k] !== 'undefined')
            .map(
              (k) =>
                `${encodeURIComponent(`${key}.${k}`)}=${encodeURIComponent(
                  value[k]
                )}`
            )
            .join('&');
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(
          data[key] || ''
        )}`;
      })
      .join('&');
  }

  const onSubmit = handleSubmit(async (data, e) => {
    setLoading(true);

    const formData = encode({
      ...data,
    });

    console.log(formData);

    try {
      let submitRes = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData,
      });

      if (process.env.NODE_ENV === 'development') {
        submitRes = { ok: true } as Response;
      }

      if (!data.country) {
        // Honeypot
        // LinkedIn tracking
        if (
          process.env.NODE_ENV === 'production' &&
          typeof window !== 'undefined' &&
          typeof window.lintrk === 'function'
        ) {
          // window.lintrk('track', { conversion_id: 6103817 });
        }

        const calendarRes = await fetch(
          `/.netlify/functions/ics-2022?session=${encodeURIComponent(
            data.session
          )}`
        );
        const calendarResJSON = await calendarRes.json();
        setCalendars(calendarResJSON);
        handleResponse(submitRes);
        setLoading(false);
      } else {
        handleResponse(submitRes);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  });

  const { addSnackbar, removeSnackbar } = React.useContext(SnackbarContext);

  React.useEffect(() => {
    if (formMessage.open) {
      if (formMessage.status === 'error') {
        addSnackbar(formMessage.message, formMessage.status);
      }
    } else {
      removeSnackbar();
    }
  }, [formMessage, addSnackbar, removeSnackbar]);

  // Thank You Message
  if (formMessage.open && formMessage.status === 'success') {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3 className="h4">Thank You!</h3>
        <p>
          Your registration has been submitted. You will receive the livestream
          links shortly via email.
        </p>
        {calendars && (
          <>
            <p style={{ marginBottom: '0.5em' }}>
              <strong>Add to Calendar:</strong>
            </p>
            <ul className="add-to-calendar">
              <li>
                <a target="_blank" href={calendars.google} rel="noreferrer">
                  <GoogleCalendar />
                </a>
                <a target="_blank" href={calendars.yahoo} rel="noreferrer">
                  <Yahoo />
                </a>
                <a
                  target="_blank"
                  href={`data:text/calendar;charset=utf8,${encodeURIComponent(
                    calendars.ics
                  )}`}
                  rel="noreferrer"
                >
                  <AppleCalendar />
                </a>
                <a target="_blank" href={calendars.outlook} rel="noreferrer">
                  <Outlook />
                </a>
                <a target="_blank" href={calendars.office} rel="noreferrer">
                  <Office365 />
                </a>
              </li>
            </ul>
          </>
        )}
        {/* <Script
          code={`var CMNCTPID='92820211110T0440593109294B5AF43274AC88A09CAE36AA78CF8';
          var CMNCAMOUNT = '0';
          try {
          if (typeof CMNTrackScriptRan === 'undefined') {
              var CMNTrackScriptRan=0;
          } 
          if (CMNTrackScriptRan<=1) {
            CMNTrackScriptRan+=1;
              (function (d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) { return; }
                  js = d.createElement(s); js.id = id;
                  js.src = "https://secureaddisplay.com/ctp/js/?CTPID="+ CMNCTPID +"&ConversionValue=" + CMNCAMOUNT + "&cachebust=" + new Date().getTime();
                  fjs.parentNode.insertBefore(js, fjs);
              } (document, 'script', 'cadnet-trackp-js'));
          }
          } catch (err) {}`}
        /> */}
      </div>
    );
  }

  return (
    <form
      action="/"
      method="POST"
      name="registration-2022"
      data-netlify="true"
      netlify-honeypot="country"
      onSubmit={onSubmit}
    >
      <input
        type="hidden"
        value="registration-2022"
        {...register('form-name')}
      />
      <div className="form-fields">
        <div className="form-field form-field--half">
          <label htmlFor="name">
            <span className="field-label">First Name</span>
            <input
              type="text"
              id="name"
              {...register('firstName', { required: true })}
              aria-invalid={!!errors.firstName}
            />
          </label>
        </div>
        <div className="form-field form-field--half">
          <label htmlFor="last-name">
            <span className="field-label">Last Name</span>
            <input
              type="text"
              id="last-name"
              {...register('lastName', { required: true })}
              aria-invalid={!!errors.lastName}
            />
          </label>
        </div>
        <div className="form-field form-field--half">
          <label htmlFor="email">
            <span className="field-label">Email</span>
            <input
              type="email"
              id="email"
              {...register('email', {
                required: true,
                pattern: /^\S+@\S+\.\S+$/,
              })}
              aria-invalid={!!errors.email}
            />
          </label>
        </div>
        <div className="form-field form-field--half form-field--phone">
          <label htmlFor="phone">
            <span className="field-label">
              <span>Phone&nbsp;&nbsp;</span>
              <div className="flags">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  viewBox="0 0 512 512"
                  height="16"
                  width="16"
                >
                  <circle cx="256" cy="256" r="256" fill="#f0f0f0" />
                  <g fill="#d80027">
                    <path d="M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87V256zM244.87 122.435h229.556a257.35 257.35 0 0 0-59.07-66.783H244.87v66.783zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.751 512 256 512zM37.574 389.565h436.852a254.474 254.474 0 0 0 28.755-66.783H8.819a254.474 254.474 0 0 0 28.755 66.783z" />
                  </g>
                  <path
                    fill="#0052b4"
                    d="M118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.407 257.407 0 0 0-49.652 55.337h7.475l-13.813 10.035a255.58 255.58 0 0 0-6.194 10.938l6.596 20.301-12.306-8.941a253.567 253.567 0 0 0-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.468 258.468 0 0 0 0 256h256V0c-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765L85.104 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zM220.328 230.4l-21.699-15.765L176.93 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  viewBox="0 0 619 619"
                  height="16"
                  width="16"
                >
                  <path
                    fill="red"
                    d="M619 309.5c0-85.8-34.9-163.4-91.3-219.4v438.8c56.4-56 91.3-133.6 91.3-219.4zM.3 309.5c0 85.6 34.8 163.1 91 219.1V90.4c-56.2 56-91 133.5-91 219.1zM279.3 186.9c-.4.7-.9 1.4-1.5 1.9s-1.3.9-2.1 1.1c-.8.2-1.6.3-2.4.2-.8-.1-1.6-.4-2.3-.8l-29.7-17.2 18.5 95.6c.2 1.3.1 2.6-.5 3.7s-1.5 2.1-2.6 2.7c-1.1.6-2.4.8-3.7.6-1.3-.2-2.4-.8-3.3-1.8l-38.5-41.3-9.5 22.5c-.5 1.3-1.5 2.3-2.7 2.9-1.2.6-2.6.8-3.9.5L145.8 247l16.9 52c.4 1.4.4 2.9-.2 4.2-.6 1.3-1.6 2.4-2.9 3l-19.3 9 85.6 69.3c1 .8 1.6 1.8 2 3 .3 1.2.3 2.5-.2 3.6l-10.5 29.1 78.1-13.7c1.3-.2 2.6-.1 3.8.2 1.2.4 2.4 1 3.4 1.8 1 .9 1.7 1.9 2.2 3.1.5 1.2.7 2.5.7 3.8l-4.1 78.5h16.4l-4.1-78.5c-.1-1.3.2-2.6.7-3.8.5-1.2 1.3-2.3 2.2-3.1 1-.9 2.1-1.5 3.4-1.8 1.2-.4 2.5-.4 3.8-.2l78.1 13.7-10.5-29.1c-.4-1.2-.5-2.4-.2-3.6.3-1.2 1-2.2 2-3l85.6-69.3-19.3-9c-1.3-.6-2.3-1.7-2.9-3-.6-1.3-.6-2.8-.2-4.2l16.9-52-49.3 10.5c-1.3.3-2.7.1-3.9-.5-1.2-.6-2.2-1.7-2.7-2.9l-9.5-22.5-38.6 41.4c-.9.9-2 1.5-3.3 1.8-1.3.2-2.6 0-3.7-.6s-2.1-1.5-2.6-2.7c-.6-1.1-.7-2.4-.5-3.7l18.5-95.6-29.6 17.2c-.7.4-1.5.7-2.3.8-.8.1-1.6 0-2.4-.2-.8-.2-1.5-.6-2.1-1.1-.6-.5-1.1-1.2-1.5-1.9l-30.2-59.3-30.2 59.2z"
                  />
                  <path
                    fill="#fff"
                    d="M91.3 90.4v438.3c56 55.8 133.1 90.2 218.3 90.2 85.1 0 162.1-34.4 218.1-89.9V90.1C471.8 34.5 394.7.2 309.6.2 224.4.1 147.2 34.6 91.3 90.4zm249.9 98.4c.6.5 1.3.9 2.1 1.1.8.2 1.6.3 2.4.2.8-.1 1.6-.4 2.3-.8l29.7-17.2-18.5 95.6c-.2 1.3-.1 2.6.5 3.7s1.5 2.1 2.6 2.7c1.1.6 2.4.8 3.7.6 1.3-.2 2.4-.8 3.3-1.8l38.5-41.3 9.5 22.5c.5 1.3 1.5 2.3 2.7 2.9 1.2.6 2.6.8 3.9.5l49.3-10.5-16.9 52c-.4 1.4-.4 2.9.2 4.2.6 1.3 1.6 2.4 2.9 3l19.3 9-85.6 69.3c-1 .8-1.6 1.8-2 3-.3 1.2-.3 2.5.2 3.6l10.5 29.1-78.1-13.7c-1.3-.2-2.6-.1-3.8.2-1.2.4-2.4 1-3.4 1.8-1 .9-1.7 1.9-2.2 3.1-.5 1.2-.7 2.5-.7 3.8l4.1 78.5h-16.4l4.1-78.5c.1-1.3-.2-2.6-.7-3.8-.5-1.2-1.3-2.3-2.2-3.1-1-.9-2.1-1.5-3.4-1.8-1.2-.4-2.5-.4-3.8-.2l-78.1 13.7 10.5-29.1c.4-1.2.5-2.4.2-3.6-.3-1.2-1-2.2-2-3l-85.6-69.3 19.3-9c1.3-.6 2.3-1.7 2.9-3 .6-1.3.6-2.8.2-4.2l-16.9-52 49.3 10.5c1.3.3 2.7.1 3.9-.5 1.2-.6 2.2-1.7 2.7-2.9l9.5-22.5 38.5 41.3c.9.9 2 1.5 3.3 1.8 1.3.2 2.6 0 3.7-.6s2.1-1.5 2.6-2.7c.6-1.1.7-2.4.5-3.7l-18.5-95.6 29.7 17.2c.7.4 1.5.7 2.3.8.8.1 1.6 0 2.4-.2.8-.2 1.5-.6 2.1-1.1.6-.5 1.1-1.2 1.5-1.9l30.2-59.3 30.2 59.3c.3.8.9 1.4 1.5 1.9z"
                  />
                </svg>
              </div>
            </span>
            <input
              type="tel"
              id="phone"
              aria-invalid={!!errors.phone}
              {...register('phone', {
                validate: {
                  isValidPhone: (value) => {
                    if (!value) {
                      return true;
                    }
                    return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
                      value
                    );
                  },
                },
              })}
            />
            {(errors.phone?.type === 'isValidPhone' && (
              <p
                className="form-field-message small"
                style={{ color: 'var(--color-error)' }}
              >
                Phone number must be a valid US number.
              </p>
            )) || (
              <FormMessageTooltip
                label="Optional"
                message="Add if you would like to receive event text notifications."
              />
            )}
          </label>
        </div>
        <div className="form-field">
          <fieldset className="radio-group" aria-invalid={!!errors.interest}>
            <legend className="radio-group__label">Interested in:</legend>
            <div className="radio-group__radio">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="on-campus"
                    name="interest.onCampus"
                    value={value}
                    // currentValue={value}
                    onChange={onChange}
                    label="On-Campus Study"
                    invalid={!!errors.interest}
                  />
                )}
                control={control}
                name="interest.onCampus"
                rules={{
                  validate: () => {
                    const values = getValues('interest');
                    const isValid = Object.values(values).some(Boolean);

                    if (isValid) {
                      clearErrors('interest');
                    }
                    return Object.values(values).some(Boolean);
                  },
                }}
              />
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="distance"
                    name="interest.distance"
                    value={value}
                    // currentValue={value}
                    onChange={onChange}
                    label="Distance Education"
                    invalid={!!errors.interest}
                  />
                )}
                control={control}
                name="interest.distance"
                rules={{
                  validate: () => {
                    const values = getValues('interest');
                    const isValid = Object.values(values).some(Boolean);

                    if (isValid) {
                      clearErrors('interest');
                    }
                    return Object.values(values).some(Boolean);
                  },
                }}
              />
            </div>
          </fieldset>
        </div>
        <div className="form-field">
          {/* Using htmlFor for some reason causes Netlify to not recognize the "State" label, which makes it messy */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <span className="field-label">Choose Your Breakout Session:</span>
            <div className="select-wrapper">
              <select
                // id="state"
                {...register('session', { required: true })}
                aria-invalid={!!errors.session}
              >
                <option value="">---</option>
                {breakoutSessions.map((s) => (
                  <option key={`option-${s.title}`} value={s.title}>
                    {s.title}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </div>
        <div className="form-field">
          <label htmlFor="questions">
            <span className="field-label">
              Questions for the Faculty or Admissions:
            </span>
            <textarea id="questions" {...register('questions')} />
          </label>
        </div>
        <div style={{ display: 'none' }}>
          <label htmlFor="country">
            Don’t fill this out if you’re human:{' '}
            <input
              type="text"
              autoComplete="false"
              tabIndex={-1}
              {...register('country')}
            />
          </label>
        </div>
        <div className="form-field form-field--submit">
          <FormSubmitButton loading={loading} text="Register Now *" />
        </div>
        <div className="form-field">
          <p className="small">
            * A weblink will be provided after registration to view the program
            online.
          </p>
        </div>
      </div>
    </form>
  );
}
