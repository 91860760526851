export const breakoutSessionsRaw = [
  {
    title: 'The Transfiguration of Hope in the Book of Job, Dr. Israel McGrew',
    image: 'israel-mcgrew',
    desc: `Job remains one of the most challenging books in the Bible; it directly questions the fulfillment of God's promises and divine and human justice. In this talk, we reflect on Christ's personal transfiguration as part of the divine pedagogy, wherein God transfigures Israel's religious expectations and scriptural heritage to reveal the nature of the Kingdom of God. We then turn to the book of Job to study its influence on reorienting Israel's piety and religious hopes in God's plan for salvation.`,
    zoom: {
      title: 'The Transfiguration of Hope in the Book of Job',
      link: 'https://augustineinstitute-org.zoom.us/j/98702032046',
      id: '987 0203 2046',
      localNumber: 'https://augustineinstitute-org.zoom.us/u/aHWRFAKXW',
    },
  },
  {
    title:
      'St. Thomas Aquinas on the Theological Virtue of Hope, Dr. Jessica Murdoch',
    image: 'jessica-murdoch',
    desc: `As Pope Benedict XVI wrote, “He who hopes lives differently. He who hopes has been given a new life.” What is the virtue of hope, and why is it important? What are the two chief vices that act against hope? In this talk, we consider St. Thomas Aquinas’ discussion of this most important virtue.`,
    zoom: {
      title: 'St. Thomas Aquinas on the Theological Virtue of Hope',
      link: 'https://augustineinstitute-org.zoom.us/j/99918640849',
      id: '999 186 40849',
      localNumber: 'https://augustineinstitute-org.zoom.us/u/am6OddNiM',
    },
  },
  {
    title: 'The Hope of the Martyrs, Dr. Christopher Mooney',
    image: 'christopher-mooney',
    desc: `How is it that the early Christian martyrs, despite their suffering and persecution, could express such vibrant, undimmed hope? Why not despair? Were they just optimists? What gave them hope? What does it mean to be a martyr? In this presentation, we examine accounts of early Christian martyrs to understand their witness to all the faithful: what it means to hope in Christ, even unto death.`,
    zoom: {
      title: 'The Hope of the Martyrs',
      link: 'https://augustineinstitute-org.zoom.us/j/94897996282',
      id: '948 9799 6282',
      localNumber: 'https://augustineinstitute-org.zoom.us/u/admpCov3vn',
    },
  },
];
