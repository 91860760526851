import { StaticImage } from 'gatsby-plugin-image';

export default function EventImage({ className = '' }: { className?: string }) {
  return (
    <div className={`event-image ${className}`}>
      <StaticImage
        src="../../images/2022/home-img.jpg"
        alt=""
        placeholder="blurred"
      />
    </div>
  );
}
