import { StaticImage } from 'gatsby-plugin-image';
import { BiLinkExternal } from 'react-icons/bi';

export default function Bonus() {
  return (
    <div className="event-bonus">
      <div className="event-bonus__text">
        <div className="event-bonus__text__box">
          <h3 className="h6">
            All attendees will be entered into a raffle to win a Bible or a book
            for Advent:
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 35 35"
              width="35"
              height="35"
            >
              <circle cx="32.5" cy="32.5" r="2.5" />
              <circle cx="32.5" cy="22.5" r="2.5" />
              <circle cx="32.5" cy="12.5" r="2.5" />
              <circle cx="32.5" cy="2.5" r="2.5" />
              <circle cx="22.5" cy="32.5" r="2.5" />
              <circle cx="22.5" cy="22.5" r="2.5" />
              <circle cx="12.5" cy="22.5" r="2.5" />
              <circle cx="12.5" cy="32.5" r="2.5" />
              <circle cx="2.5" cy="32.5" r="2.5" />
            </svg>
          </h3>
        </div>
        <div className="event-bonus__text__books">
          <ul>
            <li>
              <a
                href="https://catholic.market/bibles/catholic-edition-the-augustine-holy-bible-bonded-leather-navy/"
                className="event-bonus__text__books__book"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="event-bonus__text__books__book__img">
                  <StaticImage
                    src="../../images/books/holy-bible.jpg"
                    alt="ESV-Catholic Edition Bible"
                  />
                </div>
                <p className="event-bonus__text__books__book__text">
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ whiteSpace: 'normal' }}>
                      ESV-Catholic Edition Bible
                    </span>
                    &nbsp;
                    <BiLinkExternal />
                  </span>
                </p>
              </a>
            </li>
            <li>
              <a
                href="https://catholic.market/authors-not-featured/st-john-henry-newman/waiting-for-christ/"
                className="event-bonus__text__books__book"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="event-bonus__text__books__book__img">
                  <StaticImage
                    src="../../images/books/waiting-for-christ.jpg"
                    alt="Waiting for Christ: Meditations for Advent and Christmas"
                  />
                </div>
                <p className="event-bonus__text__books__book__text">
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ whiteSpace: 'normal' }}>
                      Waiting for Christ: Meditations for Advent and Christmas
                    </span>
                    &nbsp;
                    <BiLinkExternal />
                  </span>
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
